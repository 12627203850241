// PolarAreaChart.js
import React from 'react';
import { PolarArea } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, RadialLinearScale, Tooltip, Legend } from 'chart.js';

// Register the required components for the Polar Area chart
ChartJS.register(ArcElement, RadialLinearScale, Tooltip, Legend);

const PolarAreaChart = ({ chartData }) => {
    return (
        <div className="card card-danger mb-0">
            <div className="card-header">
                <h3 className="card-title">Max Content Played in Date Range</h3>
            </div>
            <div className="card-body">
                <PolarArea data={chartData} />
            </div>
        </div>
    );
};

export default PolarAreaChart;

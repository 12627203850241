import React from 'react'

export const MagazineHome = () => {
    return (


        <div className="container-fluid">
            {/* <h4 className='mb-2'>Data Content Records</h4> */}
            <div className='w-100'>
            <img className='rounded-2' width={'100%'} height={'100%'} src={require('../../../assets/images/magazine/magazine_lenya.jpg')}/>
            </div>
        </div>

    )
}

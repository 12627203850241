
export const validatePDFFile = ((UploadingFile) => {
    var allowedTypes = ['application/pdf']
    if (!allowedTypes.includes(UploadingFile.type)) {
        return 'Only PDF format allowed'
    }
    return true
})


export const validateImage = ((UploadingFile) => {
    var allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif']
    if (!allowedTypes.includes(UploadingFile.type)) {
        return 'Only JPG,PNG,JPEG format allowed'
    }
    return true
})


export const validateAdvertiseFile = ((FileType, UploadingFile) => {
    if (FileType === '') {
        return 'Firstly choose Advertise Type then Re-upload the file'
    }
    var allowedTypes = []
    if (FileType === 'Banner' ||  FileType === 'Interstitial') {
        allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'];
    }
    else if (FileType === 'Pre-roll') {
        allowedTypes = ['video/mp4'];
    }
    if (!allowedTypes.includes(UploadingFile.type)) {
        if (FileType === 'Banner' || FileType === 'Interstitial') {
            return 'Only JPG , JPEG , PNG , GIF formats allowed'
        }
        else if (FileType === 'Pre-roll') {
            return 'Only MP4 format allowed'
        }
        else {
            return 'Only JPG , JPEG , PNG , GIF & MP4 formats allowed'
        }
    }
    return true
})


export const validateEmail = (values) => {
    const errors = {}
  
    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    return errors
  }
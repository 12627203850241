import { DELETE_DEVICE, DELETE_PARTNERS, GET_DEVICES, GET_PARTNERS, GET_PLATFORM_ACCESS_USERS, LiveContentCount, MaxContentPlayed, MaxMediaPlayCount, PlatformWiseUser, REGISTER_DEVICES, REGISTER_PARTNERS, UPDATE_DEVICE, } from "./apiEndpoints"
import { DeleteAPI, GetAPI, PatchAPI, PostAPI } from "./apiMethods"


// Device and partner api methods //////////////////////////////////////////////////////
export const GET_REGISTERED_DEVICES = (async (queryParam, type) => {
    let url = GET_DEVICES
    if (type === "partner") {
        url = GET_DEVICES + '?partnerId=' + queryParam
    }
    return GetAPI(url)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const REGISTER_A_DEVICE = (async (Payload) => {

    return PostAPI(REGISTER_DEVICES, Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const GET_REGISTERED_PARTNERS = (async () => {
    return GetAPI(GET_PARTNERS)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

export const REGISTER_A_PARTNER = (async (Payload) => {

    return PostAPI(REGISTER_PARTNERS, Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

// Delete a partner
export const DELETE_A_PARTNER = (async (PARTNERID) => {
    return DeleteAPI(DELETE_PARTNERS+'?partnerId='+PARTNERID)
        .then((data) => {
            return data
        }).catch((error) => {
            console.log(error)
            return error
        })
})

// Delete a device
export const DELETE_A_DEVICE = (async (DEVICEID) => {
    return DeleteAPI(DELETE_DEVICE+'?deviceId='+DEVICEID)
        .then((data) => {
            return data
        }).catch((error) => {
            console.log(error)
            return error
        })
})

//Update a device
export const UPDATE_A_DEVICE = (async (bodypaload) => {
    return PatchAPI(UPDATE_DEVICE, bodypaload)
    .then((data) => {
        return data
    })
    .catch((error) => {
        console.log(error)
        return error
    })
})

// Get all users which comes on platfrom 
export const GET_PLATFORM_USERS = (async (Payload) => {

    return PostAPI(GET_PLATFORM_ACCESS_USERS, Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

// Get all Content which Live on platfrom 
export const getLiveContentCount = (async () => {

    return GetAPI(LiveContentCount)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})


// Get Last 30 days sync data logs
export const getLast30daysPlayCount = (async (Payload) => {

    return PostAPI(MaxMediaPlayCount,Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})


// Get Last 30 days sync data logs
export const MaxContentPlayedCount = (async (Payload) => {

    return PostAPI(MaxContentPlayed,Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})

// Get Last 30 days sync data logs
export const AllDeviceUserCount = (async (Payload) => {

    return PostAPI(PlatformWiseUser,Payload)
        .then((data) => {
            return data
        })
        .catch((error) => {
            console.log(error)
            return error
        })
})




import React, { useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

// Register the necessary components from Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Linechart = ({ onYearChange, MonthwiseData }) => {
  // State for selected year, default is 2024
  const [selectedYear, setSelectedYear] = useState(2024);
  console.log(MonthwiseData)
  console.log(MonthwiseData)
  // Handle year selection change
  const handleYearChange = (event) => {
    const year = parseInt(event.target.value);
    setSelectedYear(year);
    // Call the parent component's function to update the year
    onYearChange(year);
  };

  // Example labels for x-axis (months)
  const labels = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  // Example data for the chart based on the selected year
  const data = {
    labels: labels,
    datasets: [{
      label: `Data for ${selectedYear}`,
      data: MonthwiseData,
      fill: false,
      borderColor: 'rgb(75, 192, 192)', // Line color
      tension: 0.1, // Curve tension for smoothness
    }],
  };

  // Chart configuration
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Line Chart for ${selectedYear}`, // Dynamic title based on the selected year
      },
    },
  };

  return (
    <div className="card card-danger mb-0 w-100">
      <div className="card-header">
        <h3 className="card-title">Platform User Month wise</h3>
          {/* <h3 className='float-left'>Select Year</h3> */}
          {/* Dropdown for selecting the year */}
          {/* <select className="m-2" value={selectedYear} onChange={handleYearChange}>
        {Array.from({ length: 7 }, (_, i) => 2024 + i).map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select> */}
      </div>

      {/* Render the line chart using react-chartjs-2's Line component */}
      <div className="card-body">
        <Line data={data} options={options} />
      </div>
    </div>
  );
};

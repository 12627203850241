import React, { useEffect, useState } from 'react'
import { GET_ALL_LISTED_KENYA_CITIES } from '../config/content_function'
import { Datatablefile } from '../Vendor/Datatablefile'
import { KenyacityInsert } from './KenyacityInsert'

export const KenyaCities = () => {

  const [CitiesListingData, setCitiesListingData] = useState([])
  const [SelectedType, setSelectedType] = useState('citylist')

  const Dataload = () => {
    tabledata["spinnerVisible"] = true
    GET_ALL_LISTED_KENYA_CITIES().then((data) => {
      if (data?.status === true) {
        tabledata["spinnerVisible"] = false
        setCitiesListingData(data?.data)
      }
    })
      .catch((err) => {
        console.log(err)
      })
  }


  useEffect(() => {
    Dataload()
  }, [])


  const tabledata = {
    type: 'City List',
    heading: `<b>Total : </b>${CitiesListingData?.length}`,
    tableData: CitiesListingData,
    spinnerVisible: false,
    titles: ['Id', 'City Name', 'Airport Name', 'Terminal Code', 'Status', 'Country'],
    dataIndexes: ['id', 'city_name', 'airport_name', 'terminal_code', 'status', 'country']
  }

  const updateType = (type) => {
    setSelectedType(type)
  }
  const isLogsSelected = SelectedType === 'citylist';

  const UpdateBtn = (data) => {
    setSelectedType(data)
    Dataload()
  }

  return (
    <section className="content-header">
      <section className='container-head my-3 dashboard-content'>
        <div className='d-flex ml-4 pl-3'>
          <button className={`btn ${isLogsSelected ? 'btn-primary' : 'btn-secondary'} mr-3`} onClick={() => updateType('citylist')}>City list</button>
          <button className={`btn ${isLogsSelected ? 'btn-secondary' : 'btn-primary'}`} onClick={() => updateType('newcity')}>Add New City</button>
        </div>
      </section>
      <div className="container-fluid">

        {
          isLogsSelected ? <Datatablefile data={tabledata} /> :
            <KenyacityInsert callback={UpdateBtn} />
        }
      </div>
    </section>
  )
}

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-form-item-label {
    text-align: start !important;
    overflow: inherit;
}
.ant-form-item-label label{
    overflow: inherit !important;
}
.ant-select {
    width: 100%;
}
.ant-picker{
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Advertise/components/ads.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,iBAAiB;AACrB;AACA;IACI,4BAA4B;AAChC;AACA;IACI,WAAW;AACf;AACA;IACI,WAAW;AACf","sourcesContent":[".ant-form-item-label {\n    text-align: start !important;\n    overflow: inherit;\n}\n.ant-form-item-label label{\n    overflow: inherit !important;\n}\n.ant-select {\n    width: 100%;\n}\n.ant-picker{\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

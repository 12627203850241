import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const MultiLineChart = ({ data }) => {
  const addSymbols = (e) => {
    const suffixes = ["", "K", "M", "B"];
    const order = Math.max(Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)), 0);
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    const suffix = suffixes[order];
    return CanvasJSReact.CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  };

  // Convert props data to CanvasJS dataPoints format
  const convertToDataPoints = (categoryData) => {
    return Object.entries(categoryData).map(([date, count]) => ({
      x: new Date(date), // Convert date string to Date object
      y: count,
    }));
  };

  const [options, setOptions] = useState({
    animationEnabled: true,
    theme: "light2",
    title: {
      // text: "Last 30 days Sync Count"
    },
    axisX: {
      valueFormatString: "DD MMM",
      crosshair: {
        enabled: true,
        snapToDataPoint: true
      }
    },
    axisY: {
      title: "Logs Count",
      crosshair: {
        enabled: true
      },
      labelFormatter: addSymbols
    },
    toolTip: {
      shared: true
    },
    legend: {
      cursor: "pointer",
      verticalAlign: "top",
      horizontalAlign: "right",
      dockInsidePlotArea: true,
      itemclick: (e) => {
        if (typeof e.dataSeries.visible === "undefined" || e.dataSeries.visible) {
          e.dataSeries.visible = false;
        } else {
          e.dataSeries.visible = true;
        }
        e.chart.render();
      }
    },
    data: []
  });

  useEffect(() => {
    if (data) {
      const moviesDataPoints = convertToDataPoints(data.movies || {});
      const tvShowsDataPoints = convertToDataPoints(data.tvshows || {});
      const gamesDataPoints = convertToDataPoints(data.games || {});
      const musicDataPoints = convertToDataPoints(data.music || {});
      const othersDataPoints = convertToDataPoints(data.others || {});

      // Set options dynamically
      setOptions({
        ...options,
        data: [
          {
            type: "line",
            showInLegend: true,
            name: "Movies",
            dataPoints: moviesDataPoints
          },
          {
            type: "line",
            showInLegend: true,
            name: "TV Shows",
            dataPoints: tvShowsDataPoints
          },
          {
            type: "line",
            showInLegend: true,
            name: "Games",
            dataPoints: gamesDataPoints
          },
          {
            type: "line",
            showInLegend: true,
            name: "Music",
            dataPoints: musicDataPoints
          },
          {
            type: "line",
            showInLegend: true,
            name: "Others",
            dataPoints: othersDataPoints
          }
        ]
      });
    }
  }, [data]); // Re-run when `data` changes

  return (
    <div className="card card-danger mb-0">
      <div className="card-header">
        <h3 className="card-title">Last 30 days Device 1 - Sync Count</h3>
      </div>
      <div className="card-body">
        <CanvasJSChart options={options} />
      </div>
    </div>
  );
};

export default MultiLineChart;

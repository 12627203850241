import React, { useEffect, useState } from 'react'
import { GET_PLATFORM_USERS, getLiveContentCount } from '../../../config/device_functions'
import Pyramid from '../../../Vendor/charts/Pyramid'
import { useSelector } from 'react-redux'
import { Linechart } from '../../../Vendor/charts/Linechart'
// import DataInfoDrawer from '../../../Vendor/DataInfoDrawer'

export const AnalyticsHome = () => {
    const [PyramidData, SetPyramidData] = useState({})
    const [MonthWiseCount, SetMonthwiseCount] = useState([])
    const [selectedYear, setSelectedYear] = useState(2024); // Default year
    const [userCount, SetuserCount] = useState(0)

    const DateRange = useSelector((state) => state.filter.DateRange)
    const dataFilters = useSelector((state) => state.filter.FilteredItems)
    const loginTypeval = useSelector((state) => state.AuthSlice.loginType)
    const PartnerId = useSelector((state) => state.AuthSlice.partnerId)
  

    useEffect(() => {
        // Device Live content Count
        getLiveContentCount().then((resp) => {
            if (resp?.data?.status == true) { SetPyramidData(resp?.data?.data); }
        })

    }, [])

    const LoadContent = (pagenum = {
        current: 1,
        pageSize: 20,
    }) => {
        const body = {
            "filter": {
                "startDate": '2024-01-01',
                "endDate": '2024-12-30',
                // "startDate": DateRange?.startDate,
                // "endDate": DateRange?.endDate,
                "LinechartYear": selectedYear,
            }
        }

        if (loginTypeval === 'partner') {
            body.filter.partnerId = PartnerId
        }

        if (dataFilters && dataFilters?.selectedPartnerId) {
            body.filter.partnerId = dataFilters?.selectedPartnerId[0]
        }

        if (dataFilters && dataFilters?.selectedDeviceId) {
            body.filter.deviceId = dataFilters?.selectedDeviceId
        }

        GET_PLATFORM_USERS(body)
            .then((resp) => {
                if (resp?.status === 200) {
                    SetMonthwiseCount(resp?.data.Monthwise_user)
                    // setotherLogsdata(resp?.data.data)
                    SetuserCount(resp?.data.userCount)
                }
            })
            .catch((err) => console.log("Data not found Msg :", err))
    }

    useEffect(() => {
        LoadContent()
    }, [DateRange, dataFilters]);

    return (


        <div className="container-fluid">
            {/* <h4 className='mb-2'>Data analysis with chart / graphs ( Dummy data)</h4>
            <h4 className='mb-2'>Data Content Records</h4>
            <DataInfoDrawer /> */}
            {/* <div className='m-auto mt-5 w-50'>
            <img width={'100%'} height={'100%'} src={require('../../../assets/images/analytics_dash.png')} alt="Analytics"/>
            </div> */}

            <div className="bg-gradient-white m-3 h-100 w-75">
                <Linechart onYearChange={(year) => setSelectedYear(year)} MonthwiseData={MonthWiseCount} />
            </div>

            <div className=' m-3 w-50'>
                <Pyramid data={PyramidData} />
            </div>
        </div>

    )
}

import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { App } from '../App';
import { Login } from '../pages/auth/Login';
import { Register } from '../pages/auth/Register';
import { Forgotpassword } from '../pages/auth/Forgotpassword';
import { Recoverpassword } from '../pages/auth/Recoverpassword';
import { Device_register } from '../pages/device/Device_register';
import { Partner_register } from '../pages/partner/Partner_register';
import { ProtectedRoute } from '../utils/ProtectedRoute';
import { Dashboard } from '../components/MainLayouts/Dashboard';
import { MoviesLogs } from '../components/MainLayouts/MoviesLogs';
import { GamesLogs } from '../components/MainLayouts/GamesLogs';
import { MusicLogs } from '../components/MainLayouts/MusicLogs';
import { TvshowsLogs } from '../components/MainLayouts/TvshowsLogs';
import { DeviceListing } from '../components/MainLayouts/Listing/DeviceListing';
import { PartnerListing } from '../components/MainLayouts/Listing/PartnerListing';
import { SurveyFeedback } from '../components/MainLayouts/CabinCrewForms/SurveyFeedback';
import { CabinDefectLogs } from '../components/MainLayouts/CabinCrewForms/CabinDefectLogs';
import { CabBookings } from '../components/MainLayouts/CabinCrewForms/CabBookings';
import { HotelBookings } from '../components/MainLayouts/CabinCrewForms/HotelBookings';
import { FrequentFlyers } from '../components/MainLayouts/CabinCrewForms/FrequentFlyers';
import { DashboardHome } from '../components/MainLayouts/Dashboard/DashboardHome';
import { Setting } from '../components/MainLayouts/Setting';
import { useSelector } from 'react-redux';
import { NewsPaperUpload } from '../Newspaper/components/NewsPaperUpload';
import { NewsPaperListing } from '../Newspaper/components/NewsPaperListing';
import { AdsListing } from '../Advertise/components/AdsListing';
import { AdsUpload } from '../Advertise/components/AdsUpload';
import { MagazineListing } from '../Magazine/components/MagazineListing';
import { MagazineUpload } from '../Magazine/components/MagazineUpload';
import { GamesContent } from '../components/MainLayouts/DataContent/GamesContent';
import { MoviesContent } from '../components/MainLayouts/DataContent/MoviesContent';
import { MusicContent } from '../components/MainLayouts/DataContent/MusicContent';
import { TvshowsContent } from '../components/MainLayouts/DataContent/TvshowsContent';
import { OtherLogs } from '../components/MainLayouts/OtherLogs';
import { KenyaCities } from '../components/kenyaCities';
import { PlatformUsers } from '../components/MainLayouts/PlatformUsers';
import { ThemeCustomize } from '../Advertise/components/ThemeCustomize';

export const RoutePath = () => {

  const LoginType = useSelector((state) => state.AuthSlice.loginType)

 
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/* /////////// Auth Routes ////////////////////////////// */}
          <Route path='/' element={<Login />} />
          <Route path='/auth' element={<App />}>
            <Route path='login' element={<Login />} />
            <Route path="device_register" element={<ProtectedRoute><Device_register /> </ProtectedRoute>} />
            <Route path="partner_register" element={<ProtectedRoute><Partner_register /> </ProtectedRoute>} />

            <Route path="Register" element={<Register />} />
            <Route path="Forgotpassword" element={<Forgotpassword />} />
            <Route path="Recoverpassword" element={<Recoverpassword />} />
          </Route>

          {/* ///////////// Dashboard Routes//////////////// */}
          <Route path="/dashboard" element={<Dashboard />}>

            <Route path='Home' element={<ProtectedRoute><DashboardHome /></ProtectedRoute>} />

            {/* // Newspaper uploader routes////////////////////////////////////////////////////////////// */}
            {/* {
              LoginType === 'news_admin' ?
                <>
                  <Route path="NewsPaperUpload" element={<ProtectedRoute><NewsPaperUpload /> </ProtectedRoute>} />
                  <Route path="NewsPaperListing" element={<ProtectedRoute><NewsPaperListing /> </ProtectedRoute>} />
                </> : ''
            } */}

            {/* // Advertise uploader routes ///////////////////////////////////////////////////////////// */}
            {
              LoginType === 'advertise_admin' ?
                <>
                  <Route path="AdsListing" element={<ProtectedRoute><AdsListing /> </ProtectedRoute>} />
                  <Route path="AdsUpload" element={<ProtectedRoute><AdsUpload /> </ProtectedRoute>} />
                  <Route path="ThemeCustomize" element={<ProtectedRoute><ThemeCustomize /> </ProtectedRoute>} />

                  <Route path="NewsPaperUpload" element={<ProtectedRoute><NewsPaperUpload /> </ProtectedRoute>} />
                  <Route path="NewsPaperListing" element={<ProtectedRoute><NewsPaperListing /> </ProtectedRoute>} />

                  <Route path="KenyaCitiesList" element={<ProtectedRoute><KenyaCities /> </ProtectedRoute>} />


                </> : ''
            }


            {/* // Magzine uploader routes///////////////////////////////////////////////////////////////// */}
            {
              LoginType === 'magazine_admin' ?
                <>
                  <Route path="MagazineUpload" element={<ProtectedRoute><MagazineUpload /> </ProtectedRoute>} />
                  <Route path="MagazineListing" element={<ProtectedRoute><MagazineListing /> </ProtectedRoute>} />                </> : ''
            }


            {/* // Analytics panel routes/////////////////////////////////////////////////////////////////// */}
            {
              (LoginType === 'admin' || LoginType === 'partner') ?
                <>
                  <Route path="DeviceListing" element={<ProtectedRoute><DeviceListing /> </ProtectedRoute>} />
                  <Route path="PartnerListing" element={<ProtectedRoute><PartnerListing /> </ProtectedRoute>} />

                  <Route path="SurveyFeedback" element={<ProtectedRoute><SurveyFeedback /> </ProtectedRoute>} />
                  <Route path="CabinDefectLogs" element={<ProtectedRoute><CabinDefectLogs /> </ProtectedRoute>} />
                  <Route path="CabBookings" element={<ProtectedRoute><CabBookings /> </ProtectedRoute>} />
                  <Route path="HotelBookings" element={<ProtectedRoute><HotelBookings /> </ProtectedRoute>} />
                  <Route path="FrequentFlyers" element={<ProtectedRoute><FrequentFlyers /> </ProtectedRoute>} />

                  <Route path="MoviesLogs" element={<ProtectedRoute><MoviesLogs /></ProtectedRoute>} />
                  <Route path="GamesLogs" element={<ProtectedRoute><GamesLogs /></ProtectedRoute>} />
                  <Route path="MusicLogs" element={<ProtectedRoute><MusicLogs /></ProtectedRoute>} />
                  <Route path="TvshowsLogs" element={<ProtectedRoute><TvshowsLogs /></ProtectedRoute>} />
                  <Route path="otherLogs" element={<ProtectedRoute><OtherLogs /></ProtectedRoute>} />
                  <Route path="user_connections" element={<ProtectedRoute><PlatformUsers /></ProtectedRoute>} />

                  
                  {/* //content to enable disable movies and all */}
                  <Route path="MoviesContent" element={<ProtectedRoute><MoviesContent /></ProtectedRoute>} />
                  <Route path="GamesContent" element={<ProtectedRoute><GamesContent /></ProtectedRoute>} />
                  <Route path="MusicContent" element={<ProtectedRoute><MusicContent /></ProtectedRoute>} />
                  <Route path="TvshowsContent" element={<ProtectedRoute><TvshowsContent /></ProtectedRoute>} />
                  
                </> : ''
            }

            <Route path="settings" element={<ProtectedRoute><Setting /></ProtectedRoute>} />

          </Route>
        </Routes>
      </BrowserRouter>

    </>
  );
};
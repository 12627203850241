import { useState } from "react";
import {
  Button,
  Input,
  Select,
  Upload,
  Form,
  Space,
  Switch,
  ColorPicker,
} from "antd";
import { InboxOutlined, PlusOutlined, UploadOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import { DndContext, closestCenter } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
// import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { useSensor, useSensors, PointerSensor, KeyboardSensor } from "@dnd-kit/core";

const { Option } = Select;

const categoryOptions = ["Latest Movies", "TV Shows", "Web Series", "Musics", "Games"];

const defaultFooterMenu = [
  { label: "Home", visible: true },
  { label: "Cityscape", visible: true },
  { label: "Map", visible: true },
  { label: "Shopping", visible: true },
  { label: "F&B", visible: true },
];

function SortableItem({ id }) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: "8px 12px",
    border: "1px solid #d9d9d9",
    borderRadius: "4px",
    backgroundColor: "#fff",
    marginBottom: "8px",
    cursor: "grab",
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {id}
    </div>
  );
}

export const ThemeCustomize = () => {
  const [form] = Form.useForm();
  const [categories, setCategories] = useState(categoryOptions);
  const [footerMenu, setFooterMenu] = useState(defaultFooterMenu);
  const [categorySequence, setCategorySequence] = useState([...categoryOptions]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleAddCategory = () => {
    const newCat = prompt("Enter new category");
    if (newCat && !categories.includes(newCat)) {
      setCategories([...categories, newCat]);
      setCategorySequence([...categorySequence, newCat]);
    }
  };

  const handleAddFooterItem = () => {
    const newItem = prompt("Enter new footer item");
    if (newItem && !footerMenu.find((item) => item.label === newItem)) {
      setFooterMenu([...footerMenu, { label: newItem, visible: true }]);
    }
  };

  return (
    <div className="content-wrapper p-4 w-50">
      <div className="max-w-5xl mx-auto mt-6">
        <Form form={form} layout="vertical">
          <Form.Item name="partner" label="Select Partner Name">
            <Select placeholder="Select Partner">
              <Option value="partner1" selected>
                Oman Air
              </Option>
              <Option value="partner2">Partner 2</Option>
            </Select>
          </Form.Item>

          <h3 className="text-lg font-semibold mt-4 mb-2">Customize Your Theme Settings</h3>

          <div className="d-flex mt-4">
            <Form.Item name="themeColor" label="Theme Color">
              <ColorPicker />
            </Form.Item>

            <Form.Item name="textColor" label="Text Color">
              <ColorPicker />
            </Form.Item>
          </div>

          <div className="d-flex">
            <Form.Item name="fontUpload" label="Upload Font File">
              <Upload beforeUpload={() => false}>
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item name="fontName" className="ml-4" label="Enter Font Name">
              <Input placeholder="e.g. Poppins, Roboto" style={{ width: "100%" }} />
            </Form.Item>
          </div>

          <Form.Item name="logo" label="Upload Logo (PNG Only)">
            <Dragger beforeUpload={() => false} maxCount={1} accept="image/png">
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text font-medium">
                Drag & drop PNG here or click to upload
              </p>
            </Dragger>
          </Form.Item>

          <Form.Item name="homeCategories" label="Home Categories">
            <Select mode="multiple" value={categories} onChange={setCategories}>
              {categories.map((cat, index) => (
                <Option key={index} value={cat}>
                  {cat}
                </Option>
              ))}
            </Select>
            <Button icon={<PlusOutlined />} className="mt-2" onClick={handleAddCategory}>
              Add New Category
            </Button>
          </Form.Item>

          <div className="mt-4">
            <label className="font-medium mb-2 block">Category Sequence </label><span style={{fontSize:"10px"}} className="ml-2">( Drag and Drop category to change sequence)</span>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={({ active, over }) => {
                if (active.id !== over?.id) {
                  const oldIndex = categorySequence.indexOf(active.id);
                  const newIndex = categorySequence.indexOf(over?.id);
                  setCategorySequence(arrayMove(categorySequence, oldIndex, newIndex));
                }
              }}
              // modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext items={categorySequence} strategy={verticalListSortingStrategy}>
                {categorySequence.map((id) => (
                  <SortableItem key={id} id={id} />
                ))}
              </SortableContext>
            </DndContext>
          </div>

          <Form.Item label="Footer Menu Options">
            {footerMenu.map((item, index) => (
              <Space key={index} style={{ display: "flex", marginBottom: 8 }}>
                <Input value={item.label} disabled style={{ width: 150 }} />
                <Switch
                  checked={item.visible}
                  onChange={(val) => {
                    const updated = [...footerMenu];
                    updated[index].visible = val;
                    setFooterMenu(updated);
                  }}
                />
              </Space>
            ))}
            <Button icon={<PlusOutlined />} className="mt-2" onClick={handleAddFooterItem}>
              Add Footer Menu Item
            </Button>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
// BarChart.js
import React from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const BarChart = () => {
  
  // Function to add symbols (K, M, B)
  const addSymbols = (e) => {
    const suffixes = ["", "K", "M", "B"];
    const order = Math.max(Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)), 0);
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    const suffix = suffixes[order];
    return CanvasJSReact.CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  };

  // Chart options
  const options = {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Most Played Movies"
    },
    axisX: {
      title: "Social Network",
      reversed: true,
    },
    axisY: {
      title: "Monthly Active Users",
      includeZero: true,
      labelFormatter: addSymbols
    },
    data: [{
      type: "bar",
      dataPoints: [
        { y: 2200000000, label: "Facebook" },
        { y: 1800000000, label: "YouTube" },
        { y: 800000000, label: "Instagram" },
        { y: 563000000, label: "Qzone" },
        { y: 376000000, label: "Weibo" },
        { y: 336000000, label: "Twitter" },
        { y: 330000000, label: "Reddit" }
      ]
    }]
  };

  return (
    <div>
      <CanvasJSChart options={options} />
    </div>
  );
};

export default BarChart;

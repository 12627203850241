// Auth api's////////////////////////////////
export const LoginUser = 'auth/login';
export const ForgotPassword = 'auth/forgotPassword';
export const Password_OTPVerify = 'auth/ForgotPass_VerifyOTP';
export const UpdatePassword = 'auth/UpdatePassword';


// Device and partner api's////////////////////////////////
export const GET_DEVICES = 'device/getalldevices';
export const REGISTER_DEVICES = 'device/register';
export const DELETE_DEVICE = 'device/deletedevice';
export const UPDATE_DEVICE = 'device/updateDevice';
export const GET_PLATFORM_ACCESS_USERS = 'device/getUsers'

export const GET_PARTNERS = 'partner/getallpartners';
export const REGISTER_PARTNERS = 'partner/register';
export const DELETE_PARTNERS = 'partner/deletepartner';
export const UPDATE_PARTNERS = 'partner/updatePartner';


// Device event logs api's////////////////////////////////
export const GET_MOVIES = 'logs/movies';
export const GET_MUSIC = 'logs/musics';
export const GET_TVSHOWS = 'logs/tvshows';
export const GET_GAMES = 'logs/games';
export const GET_OTHERLOGS = 'logs/othersections';


// Cabin crew forms logs api's////////////////////////////////
export const GET_CABBOOKINGS = 'form/getcabBookings';
export const GET_HOTELBOOKINGS = 'form/gethotelBookings';
export const GET_CABINDEFECT = 'form/getcabinDefect';
export const GET_FEEDBACK = 'form/getfeedback';
export const GET_SURVEYFEEDBACK = 'form/getsurveyFeedback';
export const GET_SHOPPING = 'form/getshopping';
export const GET_FREQUENTFLYERS = 'form/getfrequentFlyer';


// Advertisement Api's ///////////////////////////
export const UPLOAD_AD = 'advertise/adUpload'
export const GET_ALL_ADS_DATA = 'advertise/getdeviceAds'
export const DELETE_ADS_DATA = 'advertise/deletedeviceAds'

// Newspaper Api's ///////////////////////////
export const UPLOAD_NEWSPAPER = 'newspaper/paperUpload'
export const GET_ALL_NEWSPAPER = 'newspaper/getNewspapers'
export const DELETE_NEWSPAPER = 'newspaper/paperDelete'

// Magazine Api's ///////////////////////////
export const UPLOAD_MAGAZINE = 'magazine/magazineUpload'
export const GET_ALL_MAGAZINE = 'magazine/getMagazines'
export const DELETE_MAGAZINE = 'magazine/magazineDelete'

// Panel data content API's /////////////////////////////
export const GET_MOVIES_CONTENT = 'panelData/getMovies'
export const GET_TVSHOWS_CONTENT = 'panelData/getTvshows'
export const GET_MUSICS_CONTENT = 'panelData/getMusics'
export const GET_GAMES_CONTENT = 'panelData/getGames'
export const GET_KENYA_CITIES = 'panelData/getKenyaCities'

export const UPDATE_MOVIES_CONTENT = 'panelData/UpdateMovies'
export const UPDATE_TVSHOWS_CONTENT = 'panelData/UpdateTvshows'
export const UPDATE_MUSICS_CONTENT = 'panelData/UpdateMusics'
export const UPDATE_GAMES_CONTENT = 'panelData/UpdateGames'

export const INSERT_KENYA_CITY = 'panelData/InsertCity'
export const DELETE_KENYA_CITY = 'panelData/DeleteCity'


// Graphs Api's ////////////////////
export const LiveContentCount = 'device/getLiveContentCount'
export const MaxMediaPlayCount = 'device/deviceLatestMediaPlayCount'
export const LatestSyncDevice = 'device/getLatestSyncByDevice'
export const PlatformWiseUser = 'device/deviceWiseUserCount'
export const MaxContentPlayed = 'device/deviceContentPlayed'





import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Datatablefile } from '../../Vendor/Datatablefile'
import { AllDeviceUserCount, GET_PLATFORM_USERS, getLast30daysPlayCount, getLiveContentCount, MaxContentPlayedCount } from '../../config/device_functions'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Linechart } from '../../Vendor/charts/Linechart';
import { Donut } from '../../Vendor/charts/Donut'
import PolarAreaChart from '../../Vendor/charts/PolarAreaChart'
import MultiLineChart from '../../Vendor/charts/MultiLineChart'
import Pyramid from '../../Vendor/charts/Pyramid'


export const PlatformUsers = () => {
  const [otherLogsdata, setotherLogsdata] = useState([])
  const [userCount, SetuserCount] = useState(0)
  // const [MonthWiseCount, SetMonthwiseCount] = useState([])
  const [MultiLineChartData, SetMultiLineChartData] = useState({})
  const [PolarAreaChartData, SetPolarAreaChartData] = useState([])
  const [DonutData, SetDonutData] = useState({})

  const DateRange = useSelector((state) => state.filter.DateRange)
  const dataFilters = useSelector((state) => state.filter.FilteredItems)
  const [selectedYear, setSelectedYear] = useState(2024); // Default year
  const loginTypeval = useSelector((state) => state.AuthSlice.loginType)
  const PartnerId = useSelector((state) => state.AuthSlice.partnerId)


  const LoadContent = (pagenum = {
    current: 1,
    pageSize: 20,
  }) => {
    const body = {
      "filter": {
        "startDate": DateRange?.startDate,
        "endDate": DateRange?.endDate,
        "LinechartYear": selectedYear,
      }
    }

    if (loginTypeval === 'partner') {
      body.filter.partnerId = PartnerId
    }

    if (dataFilters && dataFilters?.selectedPartnerId) {
      body.filter.partnerId = dataFilters?.selectedPartnerId[0]
    }

    if (dataFilters && dataFilters?.selectedDeviceId) {
      body.filter.deviceId = dataFilters?.selectedDeviceId
    }

    GET_PLATFORM_USERS(body)
      .then((resp) => {
        if (resp?.status === 200) {
          // SetMonthwiseCount(resp?.data.Monthwise_user)
          setotherLogsdata(resp?.data.data)
          SetuserCount(resp?.data.userCount)
        }
      })
      .catch((err) => console.log("Data not found Msg :", err))

          // Max content olayed as per the date range
    MaxContentPlayedCount({
      "filter": {
        "startDate": DateRange?.startDate,
        "endDate": DateRange?.endDate,
      }
    }).then((resp) => {
      console.log(resp)
      if (resp?.data?.status == true) {

        let data = resp?.data?.data
        let LabelsArray = []
        let DataArray = []
        data.forEach(element => {
          LabelsArray.push(Object.keys(element)[0])
          DataArray.push(Object.values(element)[0])
        });
        SetPolarAreaChartData({ Labels: LabelsArray, data: DataArray })

      }
    })
  }

  useEffect(() => {
    LoadContent()
  }, [DateRange, dataFilters, selectedYear]);

  useEffect(() => {


    // MultiLine Chart Data
    getLast30daysPlayCount({
      "deviceId": "38:d5:47:7e:17:7f"
    }).then((resp) => {
      if (resp?.data?.status == true) { SetMultiLineChartData(resp?.data?.data); }
    })




    AllDeviceUserCount({
      "filter": {
        "startDate": "2024-01-11",
        "endDate": "2024-10-04"
      }
    }).then((resp) => {
      console.log(resp)
      if (resp?.data?.status == true) { console.log(resp?.data); SetDonutData(resp?.data); }
    })

  }, [])

  const PolarAreaData = {
    labels: PolarAreaChartData?.Labels,
    datasets: [{
      label: 'Max Content Played',
      data: PolarAreaChartData?.data,
      backgroundColor: [
        'rgb(255, 99, 132)',
        'rgb(75, 192, 192)',
        'rgb(255, 205, 86)',
        'rgb(201, 203, 207)',
        'rgb(54, 162, 235)'
      ]
    }]
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="info-box mb-3 mx-3 w-50">
          <span className="info-box-icon bg-danger elevation-1">
            <FontAwesomeIcon icon={faUsers} />
          </span>
          <div className="info-box-content">
            <span className="info-box-text">Platform Total Users</span>
            <span className="info-box-number">Date Range : {DateRange?.startDate} to {DateRange?.endDate}</span>
            <span className="info-box-number">Users : {userCount}</span>
          </div>

        </div>
        <div className='d-flex'>
          {/* <div className="bg-gradient-white m-3 h-100 w-50">
            <Linechart onYearChange={(year) => setSelectedYear(year)} MonthwiseData={MonthWiseCount} />
          </div> */}
        </div>
        <div className='d-flex'>
          <div className='m-3 w-50'>
            <Donut data={DonutData} />
          </div>

          <div className='m-3 w-50'>
            <PolarAreaChart chartData={PolarAreaData} />
          </div>

        </div>
          <div className='m-3'>
            <MultiLineChart data={MultiLineChartData} />
          </div>

      </div>
    </>

  )
}

import { useCallback, useEffect, useState, useRef } from "react";
import { Button, Checkbox, DatePicker, Form, Input, Radio, Select, Spin, Upload, TimePicker,Tag } from 'antd';
import { Banner_contentType, Preroll_contentType, advertiseStatus, advertiseType } from "../helpers/constant";
import { InboxOutlined } from '@ant-design/icons';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { GET_ALL_LISTED_MOVIES, GET_ALL_LISTED_TVSHOWS } from "../../config/content_function";
import { MuiltipleSelectPicker } from "../helpers/MultipleSelect";
import { UPLOAD_YOUR_AD } from "../../config/advertisement/ad_functions";
import { UploadOutlined } from '@ant-design/icons';
import Dragger from "antd/es/upload/Dragger";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from '@ant-design/icons';
import moment from "moment";
import { validateAdvertiseFile } from "../../helper";
import './ads.css';
import axios from 'axios';
import dayjs from "dayjs";

const { Option } = Select;
const { RangePicker } = DatePicker;

const cityList = ["New York", "London", "Dubai", "Paris", "Mumbai", "Singapore", "Tokyo", "Berlin", "Sydney", "Los Angeles"];


const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};
export const AdsUpload = () => {

  const navigate = useNavigate()
  const [form] = Form.useForm();
  const toastRef = useRef(null);
  const [adtype, setadtype] = useState('')
  const [contentType, setcontentType] = useState('')
  const [contentIdlist, setcontentIdlist] = useState([])
  const [allcontentId, setallcontentId] = useState([])
  const [selectedContentId, setselectedContentId] = useState([])
  const [fileuploadpath, setfileuploadpath] = useState('')
  const [filevalidate, setfilevalidate] = useState({ 'msg': '', 'warning': false, 'uploadallowed': false })
  const [skipType, setskipType] = useState(false)
  const [skipTime, setskipTime] = useState(0)
  const [formSubmitted, setformSubmitted] = useState(false)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [sourceCity, setSourceCity] = useState('');
  const [destinationCity, setDestinationCity] = useState('');
  const [targetCount, setTargetCount] = useState('');
  const [cityList, setCityList] = useState([]);
  const [displayStartDate, setDisplayStartDate] = useState(null);
  const [displayEndDate, setDisplayEndDate] = useState(null);
  const [displayOnTimeBasis, setDisplayOnTimeBasis] = useState(false);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [sourceCities, setSourceCities] = useState([]);
  const [destinationCities, setDestinationCities] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [loading, setLoading] = useState(true);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);

  // State for checkboxes
  const [selectedOptions, setSelectedOptions] = useState({
    incoming_flight: false,
    outgoing_flight: false,
    custom_route: false
  });

  // State for selected cities
  const [selectedCities, setSelectedCities] = useState({
    incoming_flight: [],
    outgoing_flight: [],
    custom_route: []
  });



  const weekdays = [
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
    { label: "Sunday", value: "Sunday" },
  ];

  const allDaysValues = weekdays.map((day) => day.value);

  // Handle change for individual checkboxes
  const handleChange = (checkedValues) => {
    setSelectedDays(checkedValues);
  };

  // Handle "All Week Days" checkbox selection
  const handleSelectAll = (e) => {
    if (e.target.checked) {
      setSelectedDays(allDaysValues); // Select all days
    } else {
      setSelectedDays([]); // Deselect all
    }
  };
  useEffect(() => {
    // Fetch city list from API
    axios.get('https://ifeanalytics-api.aerohub.aero/api/panelData/getKenyaCities')
      .then(response => {
        const cities = response.data.data.map(city => ({
          label: `${city.city_name} - ${city.terminal_code}`,
          value: city.terminal_code,
        }));
        setCityList([{ label: 'All Cities', value: 'ALL' }, ...cities]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching city list:', error);
      });
  }, []);



  // Handle checkbox toggle
  const handleCheckboxChange = (option) => {
    setSelectedOptions((prev) => ({
      ...prev,
      [option]: !prev[option]
    }));
  };

  // Handle city selection and log data
  const handleCitySelect = (option, values) => {
    setSelectedCities((prev) => {
      const updatedCities = { ...prev, [option]: values };

      // Log the final object format
      console.log({
        incoming_flight: updatedCities.incoming_flight,
        outgoing_flight: updatedCities.outgoing_flight,
        custom_route: updatedCities.custom_route.map(route => route.join('-')) // Convert to "source-destination"
      });

      return updatedCities;
    });
  };

  useEffect(() => {
    console.log(cityList)
  }, [cityList])

  useEffect(() => {
    if (contentType === 'Movies') {
      GET_ALL_LISTED_MOVIES().then((res) => {
        if (res?.code === 200) {
          let allIds = []
          setcontentIdlist(res?.data);
          res?.data?.map((item) => allIds.push(item.id))
          setallcontentId(allIds);
        }
      }
      ).catch((err) => console.log(err))
    }
    else if (contentType === 'Tvshows') {

      GET_ALL_LISTED_TVSHOWS().then((res) => {
        if (res?.code === 200) {
          let allIds = []
          setcontentIdlist(res?.data);
          res?.data?.map((item) => allIds.push(item.id))
          setallcontentId(allIds);
        }
      }
      ).catch((err) => console.log(err))
    }
  }, [contentType])

  const removeRoute = (routeToRemove) => {
    setSelectedCities((prev) => ({
      ...prev,
      custom_route: prev.custom_route.filter((route) => route.join("-") !== routeToRemove),
    }));
  };


  const onFinish = (values) => {
    if (toastRef.current) {
      toast.dismiss(toastRef.current); // Dismiss the previous toast if it exists
    }
    const validateResp = validateAdvertiseFile(adtype, fileuploadpath)
    if (validateResp !== true) {
      setfilevalidate({ 'msg': validateResp, 'warning': true, 'uploadallowed': false })
      return
    }

    if (!filevalidate?.uploadallowed) {
      toastRef.current = toast.error('Upload right format content file', {
        position: "top-right"
      });
      return false
    }

    setformSubmitted(true)
    values["size"] = fileuploadpath.size / 1024 + 'kb'
    values["fileformat"] = fileuploadpath.type
    values["skiptimein_second"] = skipTime
    values["isskip"] = skipType

    if (adtype === 'Pre-roll') {
      if (selectedContentId.length === 0) {
        toastRef.current = toast.error('Choose Content Id', {
          position: "top-right"
        });
        return false
      }
      values["contentids"] = selectedContentId
    }

    delete values.ad_file


    // Format `daytime`
    let daytime = {};
    if (selectedDays.length > 0 && fromTime && toTime) {
      daytime = {
        day: selectedDays, // Selected weekdays
        time: fromTime, // Selected time (HH:mm)
      };
    }

    // Format `Route_info`
    let Route_info = {
      incoming_flight: selectedCities.incoming_flight,
      outgoing_flight: selectedCities.outgoing_flight,
      custom_route: selectedCities.custom_route.map((route) => route.join("-")),
    };

    let bodyPayload = {
      data: {
        ...values,
        start_date: startDate ? startDate.format("YYYY-MM-DD HH:mm:ss") : null,
        end_date: endDate ? endDate.format("YYYY-MM-DD HH:mm:ss") : null,
        Route_info: Route_info,
        target: targetCount,
        daytime: daytime,
      },
      file: fileuploadpath,
    };

    UPLOAD_YOUR_AD(bodyPayload).then((res) => {
      setformSubmitted(false)
      if (res?.data?.code === 200) {
        toastRef.current = toast.success("Ads Upload Successfully !", {
          position: "top-right"
        });
        navigate("/dashboard/AdsListing");
      }
      else {
        toastRef.current = toast.error(res?.response?.data.message, {
          position: "top-right"
        });
      }
    }).catch((err) => console.log(err))
  };


  const normFile = (e) => {
    if (e?.fileList.length) {
      setfileuploadpath(e?.file)
      const validateResp = validateAdvertiseFile(adtype, e?.file)
      if (validateResp !== true) {
        setfilevalidate({ 'msg': validateResp, 'warning': true, 'uploadallowed': false })
      }
      else if ((e?.file.size) / 1024 > 5120) {
        // content greater than 5MB not allowed
        setfilevalidate({ 'msg': 'Max 5MB content size is allowed', 'warning': true, 'uploadallowed': false })
      }
      else {
        setfilevalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })
      }
    }
    else {
      setfileuploadpath('')
      setfilevalidate({ 'msg': 'Upload a file', 'warning': true, 'uploadallowed': false })
    }
  };


  const selectedList = useCallback((data) => {
    console.log(data)
    setselectedContentId(data?.selectedContentId)
  })

  return (
    <div className="content-wrapper">
      <ToastContainer />

      <section className="content-header">
        <div className="container-fluid">
          <Form
            {...formItemLayout}
            form={form}
            name="upload"
            onFinish={onFinish}
            initialValues={{
              // prefix: '86',
            }}
            style={{
              // maxWidth: 600,
              maxWidth: "60%"
            }}
            scrollToFirstError
          >
            <div>

              <Form.Item
                name="name"
                label="Ad Name"
                rules={[
                  {
                    type: 'text',
                    message: 'The input is not valid text',
                  },
                  {
                    required: true,
                    message: 'Please input ad name',
                  },
                ]}
              >
                <Input placeholder="Enter advertisement name" />
              </Form.Item>
            </div>

            {/* Start Date & End Date */}
            <div style={{ display: "flex", gap: "10px" }}>
              <div style={{ width: "49%" }}>

                <label className="mr-3">Start date :</label>
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="Start Date & Time"

                  onChange={(date) => setStartDate(date)}
                />
              </div>
              <div style={{ width: "49%" }}>
                <label className="mr-3">End date :</label>
                <DatePicker
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  placeholder="End Date & Time"

                  onChange={(date) => setEndDate(date)}
                />
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column", gap: "10px", margin: "25px 0px" }}>
              {/* Show loading spinner while fetching cities */}
              {loading ? <Spin size="large" /> : (
                <>
                  {/* Incoming Flights */}
                  <Checkbox onChange={() => handleCheckboxChange("incoming_flight")} checked={selectedOptions.incoming_flight}>
                    All Flights coming to :
                  </Checkbox>
                  {selectedOptions.incoming_flight && (
                    <Select
                      mode="multiple"
                      placeholder="Select Incoming Cities"
                      showSearch
                      onChange={(values) => handleCitySelect("incoming_flight", values)}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      options={cityList.map(city => ({
                        label: city.label,
                        value: city.value
                      }))}
                      style={{ width: "100%" }}
                    />
                  )}

                  {/* Outgoing Flights */}
                  <Checkbox onChange={() => handleCheckboxChange("outgoing_flight")} checked={selectedOptions.outgoing_flight}>
                    All flights going from  :
                  </Checkbox>
                  {selectedOptions.outgoing_flight && (
                    <Select
                      mode="multiple"
                      placeholder="Select Outgoing Cities"
                      showSearch
                      onChange={(values) => handleCitySelect("outgoing_flight", values)}
                      filterOption={(input, option) =>
                        (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                      }
                      options={cityList.map(city => ({
                        label: city.label,
                        value: city.value
                      }))}
                      style={{ width: "100%" }}
                    />
                  )}

                  {/* Custom Route */}
                  <Checkbox onChange={() => handleCheckboxChange("custom_route")} checked={selectedOptions.custom_route}>
                    Specific Route
                  </Checkbox>
                  {selectedOptions.custom_route && (
                    <div style={{ display: "flex", gap: "10px" }}>
                      <Select
                        mode="multiple"
                        placeholder="Select Source Cities"
                        showSearch
                        onChange={(values) => handleCitySelect("custom_route", values.map((src) => [src]))}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        options={cityList.map(city => ({
                          label: city.label,
                          value: city.value
                        }))}
                        style={{ width: "50%" }}
                      />

                      <Select
                        mode="multiple"
                        placeholder="Select Destination Cities"
                        showSearch
                        onChange={(values) => setSelectedCities((prev) => {
                          const updatedRoutes = prev.custom_route.map((route, index) => [route[0], values[index]]);
                          handleCitySelect("custom_route", updatedRoutes);
                          return { ...prev, custom_route: updatedRoutes };
                        })}
                        filterOption={(input, option) =>
                          (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                        }
                        options={cityList.map(city => ({
                          label: city.label,
                          value: city.value
                        }))}
                        style={{ width: "50%" }}
                      />
                    </div>

                  )}

{selectedCities.custom_route.length > 0 && (
        <div className="flex flex-wrap gap-2 mt-2">
          {selectedCities.custom_route.map((route, index) => (
            <Tag key={index} closable onClose={() => removeRoute(route.join("-"))}>
              {route.join("-")}
            </Tag>
          ))}
        </div>
      )}

                </>
              )}
            </div>

            <Form.Item
              name="adtype"
              label="Advertise Type"
              className="mt-4"
              rules={[
                {
                  required: true,
                  message: 'Please select ad type!',
                },
              ]}
            >
              <Select placeholder="select your ad type" onChange={(e) => { setadtype(e) }}>
                {
                  advertiseType?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })
                }x
              </Select>
            </Form.Item>

            <Form.Item
              name="Status"
              label="Status"
              initialValue="Active" // Set default value
              rules={[
                {
                  required: true,
                  message: 'Please select status!',
                },
              ]}
            >
              <Select placeholder="Active" >
                <Option value="Active" selected>Active</Option>
                <Option value="Inactive">Inactive</Option>
              </Select>

              {/* <span className="text-muted" style={{ fontSize: "10px", color: "#6c757d" }}>
                The ad will remain inactive by default and requires admin approval for activation.
              </span> */}
            </Form.Item>


            <Form.Item
              name="ContentType"
              label="Content Type"
              rules={[
                {
                  required: true,
                  message: 'Please select section!',
                },
              ]}
            >
              <Select placeholder="select section" onChange={(e) => { setcontentType(e) }}>
                {
                  adtype !== '' && adtype === 'Banner' && Banner_contentType?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })
                }
                {
                  adtype !== '' && adtype === 'Interstitial' &&
                  <Option value="home" selected >Home</Option>
                }
                {
                  adtype !== '' && adtype === 'Pre-roll' && Preroll_contentType?.map((item, index) => {
                    return (
                      <Option key={index} value={item} >{item}</Option>
                    )
                  })
                }
              </Select>
            </Form.Item>

            {
              adtype === 'Pre-roll' && allcontentId?.length !== 0 &&
              <MuiltipleSelectPicker data={{ 'initialids': allcontentId, 'values': contentIdlist }} callbackOnchange={selectedList} />
            }

            {/* Target Count */}
            <Form.Item name="targetCount" label="Target">
              <Input type="number" placeholder="Enter target count e.g. 1000, 2000, 3000" onChange={(e) => setTargetCount(e.target.value)} />
            </Form.Item>

            {/* Display Ad on Time Basis */}
            <Form.Item label="Specific day and time " style={{ overflow: "inherit" }}>
              <Radio.Group
                onChange={(e) => setDisplayOnTimeBasis(e.target.value)}
                value={displayOnTimeBasis}
                className=""
              >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
            </Form.Item>


            {/* Start Time & End Time - Show only if "Yes" is selected */}
            {displayOnTimeBasis && (
              <div style={{ display: "flex", inlineSize: "max-content", flexDirection: "column", gap: "10px", marginBottom: "20px" }}>
                <label>Select Weekdays:</label>

                {/* All Week Days Checkbox */}
                <Checkbox
                  style={{ fontWeight: 100 }}
                  onChange={handleSelectAll}
                  checked={selectedDays.length === allDaysValues.length} // Check if all are selected
                  indeterminate={selectedDays.length > 0 && selectedDays.length < allDaysValues.length} // Show intermediate state
                >
                  All Days
                </Checkbox>

                {/* Individual Weekday Checkboxes */}
                <Checkbox.Group value={selectedDays} onChange={handleChange} style={{ inlineSize: "max-content" }}>
                  {weekdays.map((day) => (
                    <Checkbox key={day.value} value={day.value} style={{ fontWeight: 100 }}>
                      {day.label}
                    </Checkbox>
                  ))}
                </Checkbox.Group>


                {selectedDays.length > 0 && (
                  <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
                    <TimePicker
                      placeholder="From Time"
                      format="HH:mm"
                      onChange={(time) => setFromTime(time ? dayjs(time).format("HH:mm") : null)}
                    />
                    <TimePicker
                      placeholder="To Time"
                      format="HH:mm"
                      onChange={(time) => setToTime(time ? dayjs(time).format("HH:mm") : null)}
                    />
                  </div>
                )}
              </div>
            )}


            <Form.Item name="ad_file" label="Upload Assets" valuePropName="fileList" getValueFromEvent={normFile} >
              <Dragger
                listType="picture"
                defaultFileList={[]}
                beforeUpload={() => false}
                maxCount='1'
              // onRemove={()=>setfilevalidate({ 'msg': '', 'warning': false, 'uploadallowed': true })}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text font-weight-bold">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint font-weight-bold">Support for a single upload at a time.</p>
              </Dragger>
            </Form.Item>



            {
              filevalidate.warning ?
                <Form.Item {...tailFormItemLayout}>
                  <p className="text-danger">{filevalidate.msg}</p>
                </Form.Item>
                : ''
            }

            {
              adtype !== '' && adtype === 'Pre-roll' &&
              <>
                <Form.Item
                  name="isskip"
                  label={adtype + " skip"}
                >
                  <Select placeholder="Select Option" value={skipType} onChange={(e) => { setskipType(e) }}>
                    <Option value={false}>No</Option>
                    <Option value={true}>Yes</Option>
                  </Select>
                </Form.Item>

                <Form.Item
                  name="skiptimein_second"
                  label="Skip Time in seconds"
                  onChange={(e) => setskipTime(e.target.value)}
                >
                  <Input type="tel" value={skipTime} placeholder="Enter time in seconds e.g 2 , 5 , 10" />
                </Form.Item>
              </>
            }

            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={formSubmitted}>
                {formSubmitted ? 'Submitting....' : 'Submit'}
                {formSubmitted ? <Spin indicator={<LoadingOutlined className="fs-6 ml-2 text-white" spin />} /> : ''}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </section></div>
  );
};



import React, { useEffect, useState } from 'react'
import { Datatablefile } from '../../../Vendor/Datatablefile'
import { useSelector } from 'react-redux'
import { GET_MUSIC_EVENTS } from '../../../config/events_functions'

export const Logs = () => {
  const [musicData, setmusicData] = useState([])
  const [Pagination, setPagination] = useState({});
  const [PaginationTotalCount, setPaginationTotalCount] = useState(0);

  const DateRange = useSelector((state) => state.filter.DateRange)
  const dataFilters = useSelector((state) => state.filter.FilteredItems)

  const loginTypeval = useSelector((state) => state.AuthSlice.loginType)
  const PartnerId = useSelector((state) => state.AuthSlice.partnerId)

  const tabledata = {
    type: 'music',
    heading: `
    <b>Total : </b>${PaginationTotalCount}
    <b style="margin-left: 2em;">Clicks :</b> ${musicData?.filter((item) => (item.event === 'Click') || (item.event === 'click')).length}
    <b style="margin-left: 2em;">Viewall :</b> ${musicData?.filter((item) => item.event === 'viewall').length}
  `,
    tableData: musicData,
    spinnerVisible: false,
    titles: ['Id', 'Event', 'DeviceId', 'Name',  'Source', 'Destination', 'Subject', 'Synctime'],
    dataIndexes: ['id', 'event', 'deviceId', 'name', 'source','destination','subject', 'syncDatetime'],
    paginationDetails: PaginationTotalCount
  }

  const LoadContent = (pagenum = {
    current: 1,
    pageSize: 20,
  }) => {
    const body = {
      "pageNo": pagenum?.current,
      "pageSize": pagenum?.pageSize,
      "filter": {
        "startDate": DateRange?.startDate,
        "endDate": DateRange?.endDate,
      }
    }

    if (loginTypeval === 'partner') {
      body.filter.partnerId = PartnerId
    }

    if (dataFilters && dataFilters?.selectedPartnerId) {
      body.filter.partnerId = dataFilters?.selectedPartnerId[0]
    }

    if (dataFilters && dataFilters?.selectedDeviceId) {
      body.filter.deviceId = dataFilters?.selectedDeviceId
    }

    tabledata["spinnerVisible"] = true
    GET_MUSIC_EVENTS(body)
      .then((resp) => {
        if (resp?.status === 200) {
          setmusicData(resp?.data?.data)
          tabledata["spinnerVisible"] = false

          setPaginationTotalCount(resp?.data?.totaldata)

        }
      })
      .catch((err) => console.log("Data not found Msg :", err))

  }


  useEffect(() => {
    LoadContent()
  }, [DateRange, dataFilters])

  const paginationObj = (data) => {
    setPagination(data); // Wait for the state update to complete
  };

  useEffect(() => {
    if (Object.keys(Pagination).length) {
      LoadContent(Pagination);
    }
  }, [Pagination])

  return (
    <Datatablefile data={tabledata} paginationCallback={paginationObj} />
  )
}

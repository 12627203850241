import React, { useEffect, useState } from 'react';
import CanvasJSReact from '@canvasjs/react-charts';

const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Pyramid = (props) => {
    const [options, setOptions] = useState({
        animationEnabled: true,
        title: {
            // text: "Content Live Status"
        },
        legend: {
            horizontalAlign: "right",
            verticalAlign: "center",
            reversed: true
        },
        data: [{
            type: "pyramid",
            showInLegend: true,
            legendText: "{label}",
            indexLabel: "{label} - {y}",
            toolTipContent: "<b>{label}</b>: {y} <b>({percentage}%)</b>",
            dataPoints: [] // Empty initially, will be updated by props
        }]
    });

    useEffect(() => {
        if (props?.data) {
            // Map props data to dataPoints for the pyramid chart
            const { movies, tvshow, music, games, magazines, cityscape } = props.data;

            const dataPoints = [
                { label: "Movies", y: movies },
                { label: "TV Shows", y: tvshow },
                { label: "Music", y: music },
                { label: "Games", y: games },
                { label: "Cityscape", y: cityscape },
                { label: "Magazines", y: magazines },
            ];

            // Calculate total for percentage calculation
            const total = dataPoints.reduce((acc, point) => acc + point.y, 0);

            // Update dataPoints with percentage
            const updatedDataPoints = dataPoints.map((point) => ({
                ...point,
                percentage: ((point.y / total) * 100).toFixed(2) // Calculate percentage
            }));

            // Update options with new dataPoints
            setOptions((prevOptions) => ({
                ...prevOptions,
                data: [{ ...prevOptions.data[0], dataPoints: updatedDataPoints }]
            }));
        }
    }, [props.data]); // Re-run whenever props.data changes

    return (
        <div className="card card-danger mb-0 w-100">
            <div className="card-header">
                <h3 className="card-title">Live Content Count</h3>

            </div>

            {/* Render the line chart using react-chartjs-2's Line component */}
            <div className="card-body">
                <CanvasJSChart options={options} />
            </div>
        </div>
    );
};

export default Pyramid;
